import { homeConstants, status } from "../_constants";
import { homeServices } from "../_services/homeContent.services";

import { alert } from "../_utilities";

export const HomeActions = {
    loadHomeData,
};

function loadHomeData(data) {
    return (dispatch) => {
        dispatch(
            dispatchFunction({
                type: homeConstants.LOAD_HOME_DATA_REQUEST,
                data: null,
            })
        );
        homeServices
            .loadHomeData(data)
            .then(
                (response) => {
                    if (response?.status === status.SUCCESS) {
                        dispatch(
                            dispatchFunction({
                                type: homeConstants.LOAD_HOME_DATA_SUCCESS,
                                data: response.data,
                            })
                        );
                    } else {
                        dispatch(
                            dispatchFunction({
                                type: homeConstants.LOAD_HOME_DATA_FAILURE,
                                data: response,
                            })
                        );
                        alert.success(response.message);
                    }
                },
                (error) => {
                    dispatch(
                        dispatchFunction({
                            type: homeConstants.LOAD_HOME_DATA_FAILURE,
                            data: error.message,
                        })
                    );
                    alert.error("Oops! Something went wrong.");
                }
            )
            .catch((error) => {
                alert.error("Oops! Something went wrong.");
            });
    };
}

function dispatchFunction(data) {
    return {
        type: data.type,
        data: data.data,
    };
}


