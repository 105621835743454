import { combineReducers } from "redux";
import { contact } from "./contact.reducers";

const appReducers = combineReducers({
  contact,
});

const rootReducer = (state, action) => {
  // if (action.type === authConstants.LOGOUT) {
  //     state = undefined;
  // }
  return appReducers(state, action);
};

export default rootReducer;
