import React, { useEffect, useRef, useState } from "react";
import FooterLogo from "../assets/images/banner-logo.svg";

function Header() {
  const route = window.location.pathname;
  console.log(route);

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100px",
          cursor: "pointer",
        }}
        onClick={() => {
          window.location.href = "/";
        }}
      >
        <img src={FooterLogo} alt="" />
      </div>
    </>
  );
}

export default Header;
