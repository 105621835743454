export const config = {
  //  localApiUrl: "https://nazs.sumoordering.com",
    localApiUrl: window.location.origin,
 // localApiUrl: "https://r1.sostaging.uk",
  // localApiUrl: "https://spiceloungemanchester.sumoordering.com",
  // localApiUrl: "https://casablancamcr.com",
  // localApiUrl: "https://pascals.sumoordering.com",
  // stagingConfig: "https://r1.sostaging.uk"
  //   window.location.origin
  //     ? window.location.origin
  //     : "https://test.dsstaging.uk",
  // jwttoken:document.getElementById('tok').value,
};
