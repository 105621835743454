import React, { useEffect, useState } from "react";
import facebook from "../../assets/images/facebook.svg";
import instagram from "../../assets/images/instagram.svg";
import Snapchat from "../../assets/images/Snapchat.svg";
import tiktokblack from "../../assets/images/tiktok-black.svg";
import gmailicon from "../../assets/images/mail_icon.svg";
import locationicon from "../../assets/images/location-icon.svg";
import iconloction from "../../assets/images/icon-location.svg";
import callicon from "../../assets/images/call-icon.svg";
import foodhygiene from "../../assets/images/food-hygiene.svg";
import ordericon from "../../assets/images/order_icon.svg";
import "swiper/css";
import { Link, useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import sumologo from "../../assets/images/sumo_logo.png";
import { HomeActions } from "../../_actions/homeContent.action";
import { useDispatch, useSelector } from "react-redux";
import { status } from "../../_constants";
import { config } from "../../config";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./ReservationForm.css";
import { alert } from "../../_utilities";
function Bombay_Spice_Reservation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const home = useSelector((state) => state.home);
  const configData = JSON.parse(localStorage.getItem("configData"));

  const [homeData, sethomeData] = useState();
  const [loading, setLoading] = useState(true);
  const [isValidate, setIsValidate] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    date: null,
    time: "",
    people: "",
  });

  useEffect(() => {
    if (!home?.homeContent_data) {
      dispatch(HomeActions.loadHomeData());
    }
  }, []);

  const preloadImages = (imageUrls) => {
    return imageUrls.map((url) => {
      const img = new Image();
      img.src = url;
    });
  };

  useEffect(() => {
    if (home && home?.homeContent_status == status?.SUCCESS) {
      sethomeData(home?.homeContent_data);
      localStorage.setItem("home", JSON.stringify(home?.homeContent_data));
      const imageUrls = [
        `${config.localApiUrl}/tenancy/assets/${home?.homeContent_data?.banners[0]?.banner}`,
        `${config.localApiUrl}/tenancy/assets/${home?.homeContent_data?.banners[1]?.banner}`,
      ];
      preloadImages(imageUrls);
      setLoading(false);
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [home?.homeContent_data]);

  const timeSlots = [
    "05:00 PM",
    "05:30 PM",
    "06:00 PM",
    "06:30 PM",
    "07:00 PM",
    "07:30 PM",
    "08:00 PM",
    "08:30 PM",
    "09:00 PM",
    "09:30 PM",
    "10:00 PM",
    "10:30 PM",
  ];

  const peopleOptions = Array.from({ length: 10 }, (_, i) =>
    (i + 1).toString()
  ).concat("10+");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateChange = (date) => {
    setFormData((prev) => ({ ...prev, date }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsValidate(true);
    const errorData = validateForm(isValidate);
    if (errorData && errorData.isValid) {
      setIsValidate(false);

      const { name, email, phone, date, time, people } = formData;
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
        date: null,
        time: "",
        people: "",
      });

      alert.success(
        "Thank you for booking request we will update you as soon as possible"
      );
      setTimeout(() => {
        navigate("/");
      }, 500);

      const formattedDate = date
        ? new Intl.DateTimeFormat("en-GB", {
            day: "numeric",
            month: "long",
            year: "numeric",
          }).format(date)
        : "";

      const message =
        `*Reservation Booking Request!*\n\n` +
        `Name: ${name}\n` +
        `Email: ${email}\n` +
        `Time: ${time}, ${formattedDate}\n` +
        `Phone: ${phone}\n` +
        `Number of People: ${people}\n\n`;

      const encodedMessage = encodeURIComponent(message);
      const phoneNumber = configData.contactNumber;
      const whatsappURL = `https://wa.me/${phoneNumber}?text=${encodedMessage}`;
      window.open(whatsappURL, "_blank");
    }
  };

  const validateForm = (isValidate) => {
    const validObj = {
      message: "",
      isValid: true,
    };
    let isValid = true;
    const retData = {
      name: validObj,
      email: validObj,
      phone: validObj,
      message: validObj,
      date: validObj,
      time: validObj,
      people: validObj,
    };

    if (isValidate) {
      const { name, email, phone, message, date, time, people } = formData;

      if (!name) {
        retData.name = {
          message: "Name is required",
          isValid: false,
        };
        isValid = false;
      }

      if (!email) {
        retData.email = {
          message: "Email is required",
          isValid: false,
        };
        isValid = false;
      } else if (!/^\S+@\S+\.\S+$/.test(email)) {
        retData.email = {
          message: "Enter a valid email",
          isValid: false,
        };
        isValid = false;
      }

      if (!phone) {
        retData.phone = {
          message: "Phone number is required",
          isValid: false,
        };
        isValid = false;
      } else if (!/^\d{10}$/.test(phone)) {
        retData.phone = {
          message: "Enter a valid 10-digit phone number",
          isValid: false,
        };
        isValid = false;
      }

      if (!date) {
        retData.date = {
          message: "Date is required",
          isValid: false,
        };
        isValid = false;
      }

      if (!time) {
        retData.time = {
          message: "Time is required",
          isValid: false,
        };
        isValid = false;
      }

      if (!people) {
        retData.people = {
          message: "Number of people is required",
          isValid: false,
        };
        isValid = false;
      }

      if (message.length > 200) {
        retData.message = {
          message: "Message should be less than 200 characters",
          isValid: false,
        };
        isValid = false;
      }

      retData.isValid = isValid;
      return retData;
    }
  };
  const errorData = validateForm(isValidate);
  return (
    <>
      <div className="home-page home-page2 list-store-section">
        <div className="reservation-container">
          <h2 className="reservation-title">MAKE RESERVATION</h2>
          <p className="reservation-subtitle">
            We will confirm your booking by email. Thank you.
          </p>
          <form className="reservation-form" onSubmit={handleSubmit}>
            <DatePicker
              selected={formData.date}
              onChange={handleDateChange}
              className="reservation-input"
              placeholderText="Pick a Date"
              minDate={new Date()} // Prevent past dates
            />
            {errorData && errorData.date.message != "" && (
              <span>{errorData.date.message}</span>
            )}
            <select
              className="reservation-input"
              name="time"
              value={formData.time}
              onChange={handleChange}
            >
              <option value="">Pick a Time</option>
              {[
                "05:00 PM",
                "05:30 PM",
                "06:00 PM",
                "06:30 PM",
                "07:00 PM",
                "07:30 PM",
                "08:00 PM",
                "08:30 PM",
                "09:00 PM",
                "09:30 PM",
                "10:00 PM",
                "10:30 PM",
              ].map((t) => (
                <option key={t} value={t}>
                  {t}
                </option>
              ))}
            </select>
            {errorData && errorData.time.message != "" && (
              <span>{errorData.time.message}</span>
            )}
            <select
              className="reservation-input"
              name="people"
              value={formData.people}
              onChange={handleChange}
            >
              <option value="">Number of People</option>
              {Array.from({ length: 10 }, (_, i) => i + 1).map((num) => (
                <option key={num} value={num}>
                  {num}
                </option>
              ))}
              <option value="10+">10+</option>
            </select>
            {errorData && errorData.people.message != "" && (
              <span>{errorData.people.message}</span>
            )}
            <input
              type="text"
              name="name"
              placeholder="Name"
              className="reservation-input"
              onChange={handleChange}
            />
            {errorData && errorData.name.message != "" && (
              <span>{errorData.name.message}</span>
            )}
            <input
              type="email"
              name="email"
              placeholder="Email"
              className="reservation-input"
              onChange={handleChange}
            />{" "}
            {errorData && errorData.email.message != "" && (
              <span>{errorData.email.message}</span>
            )}
            <input
              type="tel"
              name="phone"
              placeholder="Phone"
              className="reservation-input"
              onChange={handleChange}
            />{" "}
            {errorData && errorData.phone.message != "" && (
              <span>{errorData.phone.message}</span>
            )}
            <textarea
              name="message"
              placeholder="Message..."
              className="reservation-textarea"
              onChange={handleChange}
            />
            {errorData && errorData.message.message != "" && (
              <span>{errorData.message.message}</span>
            )}
            <button type="submit" className="reservation-button">
              Submit
            </button>
          </form>
        </div>

        <div className="home-footer home-footer-2">
          <div className="container">
            <div className="row-nowrap">
              <div className="content">
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    height={30}
                    baseColor="#4f4e4e"
                    highlightColor="#eeeeee33"
                    borderRadius={20}
                    style={{ marginTop: 70 }}
                  />
                ) : (
                  // <img src={homefooterlogo} alt="" className="logo-footer" />
                  <img src={sumologo} alt="" className="logo-footer" />
                )}
              </div>
              <div className="content">
                <h3>
                  {loading ? (
                    <Skeleton
                      width={"100%"}
                      height={25}
                      baseColor="#4f4e4e"
                      highlightColor="#eeeeee33"
                      borderRadius={20}
                    />
                  ) : (
                    "Location"
                  )}
                </h3>
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    height={18}
                    baseColor="#4f4e4e"
                    highlightColor="#eeeeee33"
                    borderRadius={20}
                  />
                ) : (
                  <p>{homeData && homeData.locationData.addressLine1}</p>
                )}
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    height={18}
                    baseColor="#4f4e4e"
                    highlightColor="#eeeeee33"
                    borderRadius={20}
                  />
                ) : (
                  <p>{homeData && homeData.locationData.addressLine2}</p>
                )}
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    height={45}
                    baseColor="#4f4e4e"
                    highlightColor="#eeeeee33"
                    borderRadius={30}
                    style={{ marginTop: 20 }}
                  />
                ) : (
                  <div className="get-btn">
                    <a
                      href={homeData && homeData.locationData.directionLink}
                      target="_blank"
                    >
                      <img src={iconloction} alt="" />
                      <span>Get Directions</span>
                    </a>
                  </div>
                )}
              </div>
              <div className="content">
                <h3>
                  {loading ? (
                    <Skeleton
                      width={"100%"}
                      height={25}
                      baseColor="#4f4e4e"
                      highlightColor="#eeeeee33"
                      borderRadius={20}
                    />
                  ) : (
                    "Opening hours"
                  )}
                </h3>
                <div className="opening">
                  <div className="time">
                    <p>
                      {loading ? (
                        <Skeleton
                          width={100}
                          height={18}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={20}
                        />
                      ) : (
                        "Mon to Fri"
                      )}
                    </p>
                    <span>
                      {loading ? (
                        <Skeleton
                          width={50}
                          height={18}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={20}
                        />
                      ) : homeData && homeData.openingHoursData.Weekdays ? (
                        homeData.openingHoursData.Weekdays
                      ) : (
                        `${
                          homeData &&
                          homeData.openingHoursData &&
                          homeData.openingHoursData.Monday
                        }`
                      )}
                    </span>
                  </div>

                  <div className="time">
                    <p>
                      {loading ? (
                        <Skeleton
                          width={100}
                          height={18}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={20}
                        />
                      ) : (
                        "Saturday"
                      )}
                    </p>
                    <span>
                      {loading ? (
                        <Skeleton
                          width={50}
                          height={18}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={20}
                        />
                      ) : homeData && homeData.openingHoursData.Saturday ? (
                        homeData.openingHoursData.Saturday
                      ) : (
                        `${
                          homeData &&
                          homeData.openingHoursData &&
                          homeData.openingHoursData.Saturday
                        }`
                      )}
                    </span>
                  </div>
                  <div className="time">
                    <p>
                      {loading ? (
                        <Skeleton
                          width={100}
                          height={18}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={20}
                        />
                      ) : (
                        "Sunday"
                      )}
                    </p>
                    <span>
                      {loading ? (
                        <Skeleton
                          width={50}
                          height={18}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={20}
                        />
                      ) : homeData && homeData.openingHoursData.Sunday ? (
                        homeData.openingHoursData.Sunday
                      ) : (
                        `${
                          homeData &&
                          homeData.openingHoursData &&
                          homeData.openingHoursData.Sunday
                        }`
                      )}
                    </span>
                  </div>
                </div>
              </div>
              <div className="content">
                <h3>
                  {loading ? (
                    <Skeleton
                      width={"100%"}
                      height={25}
                      baseColor="#4f4e4e"
                      highlightColor="#eeeeee33"
                      borderRadius={20}
                    />
                  ) : (
                    "Get In Touch"
                  )}
                </h3>
                <ul>
                  <li>
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        height={18}
                        baseColor="#4f4e4e"
                        highlightColor="#eeeeee33"
                        borderRadius={20}
                      />
                    ) : (
                      <a href="tel:0161 620 2150">
                        <img src={callicon} />
                        {homeData && homeData.getintouchData.contactNumber}
                      </a>
                    )}
                  </li>
                  <li>
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        height={18}
                        baseColor="#4f4e4e"
                        highlightColor="#eeeeee33"
                        borderRadius={20}
                      />
                    ) : (
                      <a href="/">
                        <img src={locationicon} />
                        sumoshawarma.com.uk
                      </a>
                    )}
                  </li>
                  <li>
                    {loading ? (
                      <Skeleton
                        width={"100%"}
                        height={18}
                        baseColor="#4f4e4e"
                        highlightColor="#eeeeee33"
                        borderRadius={20}
                      />
                    ) : (
                      <a
                        href={`mailto:${
                          homeData && homeData.getintouchData.supportEmail
                        }`}
                      >
                        <img src={gmailicon} />
                        {homeData && homeData.getintouchData.supportEmail}
                      </a>
                    )}
                  </li>
                </ul>
              </div>
              <div className="content">
                <div className="footer-icon">
                  <ul>
                    <li>
                      {loading ? (
                        <Skeleton
                          width={30}
                          height={30}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={50}
                        />
                      ) : (
                        <a href="https://www.facebook.com">
                          <img src={facebook} alt="" />
                        </a>
                      )}
                    </li>
                    <li>
                      {loading ? (
                        <Skeleton
                          width={30}
                          height={30}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={50}
                        />
                      ) : (
                        <a href="https://www.instagram.com">
                          <img src={instagram} alt="" />
                        </a>
                      )}
                    </li>
                    <li>
                      {loading ? (
                        <Skeleton
                          width={30}
                          height={30}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={50}
                        />
                      ) : (
                        <a href="https://www.Snapchat.com">
                          <img src={Snapchat} alt="" />
                        </a>
                      )}
                    </li>
                    <li>
                      {loading ? (
                        <Skeleton
                          width={30}
                          height={30}
                          baseColor="#4f4e4e"
                          highlightColor="#eeeeee33"
                          borderRadius={50}
                        />
                      ) : (
                        <a href="https://www.tiktok.com">
                          <img src={tiktokblack} alt="" />
                        </a>
                      )}
                    </li>
                  </ul>
                </div>
                {loading ? (
                  <Skeleton
                    width={"100%"}
                    height={100}
                    baseColor="#4f4e4e"
                    highlightColor="#eeeeee33"
                    borderRadius={10}
                  />
                ) : (
                  <img src={foodhygiene} alt="" />
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="home-footer-button">
          <div className="container">
            <ul>
              <li>
                {loading ? (
                  <Skeleton
                    width={150}
                    height={54}
                    baseColor="#4f4e4e"
                    highlightColor="#eeeeee33"
                    zIndex={9}
                  />
                ) : (
                  <a href="tel:0161 620 2150">
                    <img src={callicon} alt="" />
                    <a href="tel:0161 620 2150">Call Now</a>
                  </a>
                )}
              </li>
              <li>
                {loading ? (
                  <Skeleton
                    width={150}
                    height={54}
                    baseColor="#4f4e4e"
                    highlightColor="#eeeeee33"
                    zIndex={9}
                  />
                ) : (
                  <a href="/">
                    <img src={ordericon} alt="" />
                    <Link to="/menu">Order Online</Link>
                  </a>
                )}
              </li>
              {/* <li>
                                    <img src={iconcalendar} alt="" />
                                    <a href="#">Reservation</a>
                                </li> */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
export default Bombay_Spice_Reservation;
