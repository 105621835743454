import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./tenant/assets/style/style.scss";
// import "bootstrap/dist/css/bootstrap.css";
// import { ParentDomainList } from "./config";
// import { ParentSiteLayout } from "./parent/Layout";
import CustomLoader from "./tenant/components/CustomeLoader";
import { config } from "./tenant/config";
import { ParentDomainList } from "./config";
import ParentTheme from "./parent";
import ChildTheme from "./tenant";
// import { Provider } from "react-redux";
// import { store } from "./_store";
// import CustomLoader from "./utils/commonFunctions/commonLoader";

function App() {
  const loadTheme = () => {
    const origin = window.location.origin;
    const host = window.location.hostname;
    if (ParentDomainList.includes(origin) || ParentDomainList.includes(host)) {
      return <ParentTheme />;
    } else {
      return <ChildTheme />;
    }
  };

  return loadTheme();
}

export default App;
