import { contactConstants, status } from "../_constants";

let initialState = {};
export function contact(state = initialState, action) {
  switch (action.type) {
    case contactConstants.CONTACT_REQUEST:
      return {
        ...state,
        contact_status: status.IN_PROGRESS,
        contact_data: action.data,
      };
    case contactConstants.CONTACT_SUCCESS:
      return {
        ...state,
        contact_status: status.SUCCESS,
        contact_data: action.data,
      };
    case contactConstants.CONTACT_FAILURE:
      return {
        ...state,
        contact_status: status.FAILURE,
        contact_data: action.data,
      };

    default:
      return state;
  }
}
