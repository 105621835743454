import React, { useState } from "react";
import FooterLogo from "../assets/images/banner-logo.svg";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "120px",
          flexDirection: "column",
        }}
      >
        <img src={FooterLogo} alt="" style={{ marginTop: 20 }} />
        <p
          style={{
            color: "#003049",
            fontFamily: "Lato-Bold",
            padding: 0,
            margin: 0,
          }}
        >
          © 2025. Sumo Ordering. All rights reserved
        </p>
        <p>
          <Link
            // to={"/terms"}
            to={"/#"}
            style={{
              color: "#003049",
              fontFamily: "Lato-Bold",
              padding: 0,
              margin: 0,
              textDecoration: "none",
            }}
          >
            Terms of Use
          </Link>
          <span
            style={{
              color: "#003049",
              fontFamily: "Lato-Bold",
              padding: 0,
              margin: 0,
            }}
          >
            {" "}
            •{" "}
          </span>
          <Link
            to={"/privacy"}
            style={{
              color: "#003049",
              fontFamily: "Lato-Bold",
              padding: 0,
              margin: 0,
              textDecoration: "none",
            }}
          >
            Privacy
          </Link>
        </p>
      </div>
    </>
  );
}

export default Footer;
