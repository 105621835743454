export const authConstants = {
  REGISTER_USER_REQUEST: "REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",

  VERIFY_EMAIL_REQUEST: "VERIFY_EMAIL_REQUEST",
  VERIFY_EMAIL_SUCCESS: "VERIFY_EMAIL_SUCCESS",
  VERIFY_EMAIL_FAILURE: "VERIFY_EMAIL_FAILURE",

  USER_LOGOUT: "USER_LOGOUT",
  USER_LOGIN_REQUEST: "USER_LOGIN_REQUEST",
  USER_LOGIN_SUCCESS: "USER_LOGIN_SUCCESS",
  USER_LOGIN_FAILURE: "USER_LOGIN_FAILURE",

  RESEND_OTP_REQUEST: "RESEND_OTP_REQUEST",
  RESEND_OTP_SUCCESS: "RESEND_OTP_SUCCESS",
  RESEND_OTP_FAILURE: "RESEND_OTP_FAILURE",

  USER_DATA_REQUEST: "USER_DATA_REQUEST",
  USER_DATA_SUCCESS: "USER_DATA_SUCCESS",
  USER_DATA_FAILURE: "USER_DATA_FAILURE",

  UPDATE_USER_DATA_REQUEST: "UPDATE_USER_DATA_REQUEST",
  UPDATE_USER_DATA_SUCCESS: "UPDATE_USER_DATA_SUCCESS",
  UPDATE_USER_DATA_FAILURE: "UPDATE_USER_DATA_FAILURE",

  ADDRESS_REQUEST: "ADDRESS_REQUEST",
  ADDRESS_SUCCESS: "ADDRESS_SUCCESS",
  ADDRESS_FAILURE: "ADDRESS_FAILURE",

  USER_ADDRESS_REQUEST: "USER_ADDRESS_REQUEST",
  USER_ADDRESS_SUCCESS: "USER_ADDRESS_SUCCESS",
  USER_ADDRESS_FAILURE: "USER_ADDRESS_FAILURE",

  DELETE_ADDRESS_REQUEST: "DELETE_ADDRESS_REQUEST",
  DELETE_ADDRESS_SUCCESS: "DELETE_ADDRESS_SUCCESS",
  DELETE_ADDRESS_FAILURE: "DELETE_ADDRESS_FAILURE",

  UPDATE_ADDRESS_REQUEST: "UPDATE_ADDRESS_REQUEST",
  UPDATE_ADDRESS_SUCCESS: "UPDATE_ADDRESS_SUCCESS",
  UPDATE_ADDRESS_FAILURE: "UPDATE_ADDRESS_FAILURE",

  SUBSCRIBE_REQUEST: "SUBSCRIBE_REQUEST",
  SUBSCRIBE_SUCCESS: "SUBSCRIBE_SUCCESS",
  SUBSCRIBE_FAILURE: "SUBSCRIBE_FAILURE",

  USER_LOGOUT: "USER_LOGOUT",

};
