import React from "react";

const Home = React.lazy(() => import("../Pages/Home"));
const PrivacyPolicy = React.lazy(() =>
  import("../Pages/Privacy/PrivacyPolicy")
);
const TermsAndConditions = React.lazy(() =>
  import("../Pages/TermsAndConditions/TermsAndCondition")
);

const routes = [
  {
    path: "/",
    name: "Home",
    title: "Home",
    element: <Home />,
  },
  {
    path: "/privacy",
    name: "Privacy-Policy",
    title: "Privacy-Policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/terms",
    name: "terms-and-conditions",
    title: "terms-and-conditions",
    element: <TermsAndConditions />,
  },
];

export default routes;
