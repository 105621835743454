import React from "react";
import Login from "../pages/Login";
import Verification from "../pages/VerificationCode";
import Bombay_Spice_Reservation from "../pages/Home2/Reservation";
// const SignUp = React.lazy(() => import("../pages/SignUp"));
// const LogIn = React.lazy(() => import("../pages/Login"));
// const Verification = React.lazy(() => import("../pages/VerificationCode"));
const OrderingMenu = React.lazy(() => import("../pages/OrderingMenu"));
const Cart = React.lazy(() => import("../pages/Cart"));
const Checkout = React.lazy(() => import("../pages/CheckOut"));
const ProductDetails = React.lazy(() => import("../pages/ProductDetails"));
const Promo = React.lazy(() => import("../pages/PromoCode"));
const Account = React.lazy(() => import("../pages/Account"));
const InviteFriends = React.lazy(() => import("../pages/InviteFriends"));
const StripeWraper = React.lazy(() => import("../pages/Payment"));
const Home = React.lazy(() => import("../pages/Home"));
const YourOrders = React.lazy(() => import("../pages/YourOrders"));
const CookiePolicy = React.lazy(() => import("../pages/CookiePolicy"));
const Bombay_Spice = React.lazy(() => import("../pages/Home2"));
const Fried_Chicken = React.lazy(() => import("../pages/Home3"));
const Sumo_Chippy = React.lazy(() => import("../pages/Home4"));
const Sumo_Slices = React.lazy(() => import("../pages/Home5"));
const Sumo_Bubbles = React.lazy(() => import("../pages/Home6"));
const Sumo_Sushi = React.lazy(() => import("../pages/Home7"));
const Sumo_Chai = React.lazy(() => import("../pages/Home8"));
const Sumo_Wok = React.lazy(() => import("../pages/Home9"));
const The_Best_Burgers = React.lazy(() => import("../pages/Home10"));
const Yo_Sushi = React.lazy(() => import("../pages/Home11"));
const Sumo_Sweet = React.lazy(() => import("../pages/Home12"));
const Sumo_Brunch = React.lazy(() => import("../pages/Home14"));
const Sumo_Shawarma = React.lazy(() => import("../pages/Home13"));
const Parent_Site = React.lazy(() => import("../pages/Home15"));
const ListStore = React.lazy(() => import("../pages/Home2/list_store"));
const ListStore3 = React.lazy(() => import("../pages/Home3/list_store"));
const ListStore4 = React.lazy(() => import("../pages/Home4/list_store"));
const ListStore5 = React.lazy(() => import("../pages/Home5/list_store"));
const ListStore6 = React.lazy(() => import("../pages/Home6/list_store"));
const ListStore7 = React.lazy(() => import("../pages/Home7/list_store"));
const ListStore8 = React.lazy(() => import("../pages/Home8/list_store"));
const ListStore9 = React.lazy(() => import("../pages/Home9/list_store"));
const ListStore10 = React.lazy(() => import("../pages/Home10/list_store"));
const ListStore11 = React.lazy(() => import("../pages/Home11/list_store"));
const ListStore12 = React.lazy(() => import("../pages/Home12/list_store"));

const configData = localStorage.getItem("configData") != "undefined"
  ? JSON.parse(localStorage.getItem("configData"))
  : null;

const routes = [
  // {
  //   path: "/",
  //   name: "SignUp",
  //   title: "SignUp",
  //   element: <SignUp />,
  // },
  {
    path: "/login",
    name: "LogIn",
    title: "LogIn",
    element: <Login />,
  },
  {
    path: "/verification",
    name: "Verification",
    title: "Verification",
    element: <Verification />,
  },

  {
    path: "/",
    name: "Home",
    title: "Home",
    // element: (
    //   <div>
    //     contact:
    //     <a href={`mailto:${configData.supportEmail || "support@example.com"}`}>
    //       {configData.supportEmail || "support@example.com"}
    //     </a>
    //     <h1>{configData.websiteName || "Website Name"}</h1>
    //   </div>
    // ),
    element:configData && configData.isHomePage ? <Home /> : <OrderingMenu />,
  },

  {
    path: "/menu",
    name: "OrderingMenu",
    title: "OrderingMenu",
    element: <OrderingMenu />,
  },
  {
    path: "/Bombay_Spice",
    name: "Bombay_Spice",
    title: "Bombay_Spice",
    element: <Bombay_Spice />,
  },
  {
    path: "/Fried_Chicken",
    name: "Fried_Chicken",
    title: "Fried_Chicken",
    element: <Fried_Chicken />,
  },
  {
    path: "/Sumo_Chippy",
    name: "Sumo_Chippy",
    title: "Sumo_Chippy",
    element: <Sumo_Chippy />,
  },
  {
    path: "/Sumo_Slices",
    name: "Sumo_Slices",
    title: "Sumo_Slices",
    element: <Sumo_Slices />,
  },
  {
    path: "/Parent_Site",
    name: "Parent_Site",
    title: "Parent_Site",
    element: <Parent_Site />,
  },
  {
    path: "/Sumo_Bubbles",
    name: "Sumo_Bubbles",
    title: "Sumo_Bubbles",
    element: <Sumo_Bubbles />,
  },
  {
    path: "/Sumo_Sweet",
    name: "Sumo_Sweet",
    title: "Sumo_Sweet",
    element: <Sumo_Sweet />,
  },
  {
    path: "/Sumo_Brunch",
    name: "Sumo_Brunch",
    title: "Sumo_Brunch",
    element: <Sumo_Brunch />,
  },
  {
    path: "/Sumo_Shawarma",
    name: "Sumo_Shawarma",
    title: "Sumo_Shawarma",
    element: <Sumo_Shawarma />,
  },
  {
    path: "/Sumo_Sushi",
    name: "Sumo_Sushi",
    title: "Sumo_Sushi",
    element: <Sumo_Sushi />,
  },
  {
    path: "/Sumo_Chai",
    name: "Sumo_Chai",
    title: "Sumo_Chai",
    element: <Sumo_Chai />,
  },
  {
    path: "/Sumo_Wok",
    name: "Sumo_Wok",
    title: "Sumo_Wok",
    element: <Sumo_Wok />,
  },
  {
    path: "/The_Best_Burgers",
    name: "The_Best_Burgers",
    title: "The_Best_Burgers",
    element: <The_Best_Burgers />,
  },
  {
    path: "/Yo_Sushi",
    name: "Yo_Sushi",
    title: "Yo_Sushi",
    element: <Yo_Sushi />,
  },
  {
    path: "/Reservation",
    name: "Reservation",
    title: "Reservation",
    element: <Bombay_Spice_Reservation />,
  },
  {
    path: "/list_store",
    name: "ListStore",
    title: "ListStore",
    element: <ListStore />,
  },
  {
    path: "/list_store3",
    name: "ListStore3",
    title: "ListStore3",
    element: <ListStore3 />,
  },
  {
    path: "/list_store4",
    name: "ListStore4",
    title: "ListStore4",
    element: <ListStore4 />,
  },
  {
    path: "/list_store5",
    name: "ListStore5",
    title: "ListStore5",
    element: <ListStore5 />,
  },
  {
    path: "/list_store6",
    name: "ListStore6",
    title: "ListStore6",
    element: <ListStore6 />,
  },
  {
    path: "/list_store7",
    name: "ListStore7",
    title: "ListStore7",
    element: <ListStore7 />,
  },
  {
    path: "/list_store8",
    name: "ListStore8",
    title: "ListStore8",
    element: <ListStore8 />,
  },
  {
    path: "/list_store9",
    name: "ListStore9",
    title: "ListStore9",
    element: <ListStore9 />,
  },
  {
    path: "/list_store10",
    name: "ListStore10",
    title: "ListStore10",
    element: <ListStore10 />,
  },
  {
    path: "/list_store11",
    name: "ListStore11",
    title: "ListStore11",
    element: <ListStore11 />,
  },
  {
    path: "/list_store12",
    name: "ListStore12",
    title: "ListStore12",
    element: <ListStore12 />,
  },
  {
    path: "/store/:store_id/product-details/:menu_id",
    name: "ProductDetails",
    title: "ProductDetails",
    element: <ProductDetails />,
  },
  {
    path: "/cart",
    name: "Cart",
    title: "Cart",
    element: <Cart />,
  },
  {
    path: "/orders",
    name: "yourorders",
    title: "yourorders",
    element: <YourOrders />,
  },
  {
    path: "/checkout",
    name: "Checkout",
    title: "Checkout",
    element: <Checkout />,
  },
  // {
  //   path: "/product-details/:id",
  //   name: "ProductDetails",
  //   title: "ProductDetails",
  //   element: <ProductDetails />,
  // },
  {
    path: "/promo",
    name: "Promo",
    title: "Promo",
    element: <Promo />,
  },
  {
    path: "/invite-friends",
    name: "inviteFriends",
    title: "InviteFriends",
    element: <InviteFriends />,
  },
  {
    path: "/account",
    name: "Account",
    title: "Account",
    element: <Account />,
  },
  {
    path: "/payment",
    name: "Payment",
    title: "Payment",
    element: <StripeWraper />,
  },
  {
    path: "/CookiePolicy",
    name: "CookiePolicy",
    title: "CookiePolicy",
    element: <CookiePolicy />,
  },
];

export default routes;
