import React, { Suspense, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "../_routes/routes";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "../_store";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";

export function ParentThemeLayout() {
  const createRoutes = () => {
    const retData = routes.map((route, index) => {
      return route.element ? (
        <Route
          path={route.path}
          element={route.element}
          key={`page-route-${index}`}
        />
      ) : null;
    });
    return retData;
  };

  return (
    <div className="color-theme">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={true}>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />

            <Header />
            <Routes>{createRoutes()}</Routes>
            <Footer />
          </Suspense>
        </PersistGate>
      </Provider>
    </div>
  );
}
