import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { ParentThemeLayout } from "./Layout";
import { Provider } from "react-redux";
import { store } from "./_store";
const ChildTheme = (props) => {
  const createRoutes = () => {
    return <Route path="/*" element={<ParentThemeLayout />} />;
  };

  return (
    <Provider store={store}>
      <Routes>{createRoutes()}</Routes>
    </Provider>
  );
};

export default ChildTheme;
