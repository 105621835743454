import { config } from "./../config";
import { commonFunctions } from "../_utilities";

export const orderingServices = {
  getOredringList,
  getProductDetails,
  getUserOrderList,
  getOrderDetails,
  availableDeliveryTiming,
  getStore,
  getStoreById,
};

function getOredringList(id) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    null
  );
  return fetch(
    `${config.localApiUrl}/api/menu/${id}`,
    requestOptions
  ).then((response) => response.json());
}

function getProductDetails(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    null
  );
  return fetch(
    `${config.localApiUrl}/api/menu/item/${data?.id}`,
    requestOptions
  ).then((response) => response.json());
}

function getUserOrderList() {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    null,
    true
  );
  return fetch(
    `${config.localApiUrl}/api/orders`,
    requestOptions
  ).then((response) => response.json());
}

function getOrderDetails(id) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    null,
    true
  );
  return fetch(
    `${config.localApiUrl}/api/orders/${id}`,
    requestOptions
  ).then((response) => response.json());
}

function availableDeliveryTiming() {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    null,
    true
  );
  return fetch(
    `${config.localApiUrl}/api/available-timings`,
    requestOptions
  ).then((response) => response.json());
}

function getStore() {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    null,
    true
  );
  return fetch(
    `${config.localApiUrl}/api/store`,
    requestOptions
  ).then((response) => response.json());
}

function getStoreById(id) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    null,
    true
  );
  return fetch(
    `${config.localApiUrl}/api/stores/${id}`,
    requestOptions
  ).then((response) => response.json());
}
