import { config } from "./../config";
import { commonFunctions } from "../_utilities";


export const homeServices = {
    loadHomeData,
};

function loadHomeData() {
    const extraHeaders = {
        "Content-Type": "application/json",
    };
    const requestOptions = commonFunctions.getRequestOptions(
        "get",
        extraHeaders,
    );
    return fetch(`${config.localApiUrl}/api/home`, requestOptions).then((response) =>
        response.json()
    );
}
