import React from "react";

const CustomLoader = ({ loading }) => {
  
  return (
    <div className={`loader-container ${loading ? "show" : "hide"}`}>
      <div className="custom-loader">
        <div className="loader">
        </div>
      </div>
    </div>
  );
};

export default CustomLoader;
