import { config } from "../config";
import { commonFunctions } from "../_utilities";

export const authServices = {
  register,
  login,
  resendOtp,
  userData,
  updateUserData,
  Address,
  getAddress,
  updateAddress,
  deleteAddress,
  subscribe,
};

function register(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${config.localApiUrl}/api/register`,
    requestOptions
  ).then((response) => response.json());
}
function login(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${config.localApiUrl}/api/verify-otp`,
    requestOptions
  ).then((response) => response.json());
}
function resendOtp(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${config.localApiUrl}/api/resend-otp`,
    requestOptions
  ).then((response) => response.json());
}
function userData(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    JSON.stringify(data),
    true
  );
  return fetch(
    `${config.localApiUrl}/api/customer`,
    requestOptions
  ).then((response) => response.json());
}
function updateUserData(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data),
    true
  );
  return fetch(
    `${config.localApiUrl}/api/customer`,
    requestOptions
  ).then((response) => response.json());
}
function Address(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data),
    true
  );
  return fetch(
    `${config.localApiUrl}/api/customer-addresses`,
    requestOptions
  ).then((response) => response.json());
}
function getAddress(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "GET",
    extraHeaders,
    JSON.stringify(data),
    true
  );
  return fetch(
    `${config.localApiUrl}/api/customer-addresses`,
    requestOptions
  ).then((response) => response.json());
}
function updateAddress(id, data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "PUT",
    extraHeaders,
    JSON.stringify(data),
    true
  );
  return fetch(
    `${config.localApiUrl}/api/customer-addresses/${id}`,
    requestOptions
  ).then((response) => response.json());
}

function deleteAddress(id) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "DELETE",
    extraHeaders,
    null,
    true
  );
  return fetch(
    `${config.localApiUrl}/api/customer-addresses/${id}`,
    requestOptions
  ).then((response) => response.json());
}
function subscribe(data) {
  const extraHeaders = {
    "Content-Type": "application/json",
  };
  const requestOptions = commonFunctions.getRequestOptions(
    "POST",
    extraHeaders,
    JSON.stringify(data)
  );
  return fetch(
    `${config.localApiUrl}/api/subscribe`,
    requestOptions
  ).then((response) => response.json());
}
