import React, { Suspense, useEffect } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "../_routes/routes";
import { Route, Routes } from "react-router-dom";
import { Provider } from "react-redux";
import { store, persistor } from "../_store";
import { PersistGate } from "redux-persist/integration/react";
import axios from "axios";
import { config } from "../config";
import { ToastContainer } from "react-toastify";
// import CustomLoader from "../../../../components/CustomeLoader";

export function ChildThemeLayout() {
  // useEffect(() => {
  //   axios
  //     .post(config.localApiUrl + "/api/loadConfigurationData", {
  //       section:
  //         "social_media,theme_configuration,contact_page,website_configuration,prayerTime,eveningMadrassah,jummahpay_configuration,module_access",
  //     })
  //     .then((response) => {
  //       if (response.status === 200) {
  //         if (response.data.status === 200) {
  //           if (response.data.data.configurationData) {
  //             let configData = {};
  //             response.data.data.configurationData.map((data, index) => {
  //               configData[data.key] = data.value;
  //             });

  //             // this.setState({ configData: configData });
  //             localStorage.setItem("configData", JSON.stringify(configData));
  //           }
  //         }
  //       }
  //     });
  // }, []);

  const createRoutes = () => {
    const retData = routes.map((route, index) => {
      return route.element ? (
        <Route
          path={route.path}
          element={route.element}
          key={`page-route-${index}`}
        />
      ) : null;
    });
    return retData;
  };

  return (
    <div className="color-theme">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Suspense fallback={true}>
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={true}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <Header />
            <Routes>{createRoutes()}</Routes>
            <Footer />
          </Suspense>
        </PersistGate>
      </Provider>
    </div>
  );
}
