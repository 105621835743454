import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.css";
import arrowwhite from "../../assets/images/arrow-white.svg";
import { authActions } from "../../_actions";
import { useDispatch, useSelector } from "react-redux";
import { status } from "../../_constants";
import { alert } from "../../_utilities";

function Login() {
  const [emailOrNumber, setEmailOrNumber] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState("");
  const registerData = useSelector((state) => state.auth);
  const inputRef = useRef(null);

  function validateEmailOrContact(isSubmited) {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const contactNumberRegex = /^[1-9]\d{9,14}$|^\d{10,12}$/;
    const contactNumberRegex = /^\d{10,12}$/;
    let validate = false;
    if (isSubmited) {
      if (emailRegex.test(emailOrNumber)) {
        setErrorMessage("");
        validate = true;
      } else if (contactNumberRegex.test(emailOrNumber)) {
        setErrorMessage("");
        validate = true;
      } else {
        setErrorMessage("Enter a valid input!");
        validate = false;
      }
    }
    return validate;
  }

  useEffect(() => {
    const savedUser = localStorage.getItem("user");
    if (savedUser) {
      setEmailOrNumber(savedUser);
    }
  }, []);

  useEffect(() => {
    if (emailOrNumber) {
      localStorage.setItem("user", emailOrNumber);
    }
  }, [emailOrNumber]);

  useEffect(() => {
    if (
      registerData &&
      registerData.registration_data &&
      registerData.register_status === status.SUCCESS
    ) {
      // localStorage.setItem("user", emailOrNumber)
      // setEmailOrNumber("")
      navigate("/verification");
    }
  }, [registerData.registration_data]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const contactNumberRegex = /^[1-9]\d{9,14}$|^\d{10,12}$/;
    const contactNumberRegex = /^\d{10,12}$/;
    if (validateEmailOrContact(true)) {
      if (emailRegex.test(emailOrNumber)) {
        dispatch(authActions.register({ email: emailOrNumber }));
      } else if (contactNumberRegex.test(emailOrNumber)) {
        dispatch(authActions.register({ phone: emailOrNumber }));
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      handleSubmit(e);
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  window.onload = function () {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  window.onclick = function () {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <div className="main-wrapper" id="sign-up">
      <div className="container">
        <div className="signup-page">
          <div className="text-content">
            <h2>What’s your phone number?</h2>
          </div>
          <div className="form-group-blue">
            <input
              type="tel"
              name="emailOrNumber"
              value={emailOrNumber}
              placeholder="Enter phone number"
              // onChange={(e) => setEmailOrNumber(e.target.value)}
              onChange={(e) => {
                const value = e.target.value;
                if (
                  value === "" ||
                  (value.length <= 12 && /^\d+$/.test(value))
                ) {
                  setEmailOrNumber(value);
                }
              }}
              onKeyDown={handleKeyDown}
              ref={inputRef}
            />
            {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
          </div>
          <div className="Continue-btn">
            <button
              title="Continue"
              className="primary-btn sm Continue"
              onClick={handleSubmit}
            >
              Continue
              <span>
                <img src={arrowwhite} alt="" />
              </span>
            </button>
          </div>
          <p>
            By proceeding, you consent to get calls, WhatsApp or SMS messages,
            including by automated means, from SumoEats and its affiliates to
            the number provided.
          </p>
        </div>
      </div>
    </div>
  );
}
export default Login;
