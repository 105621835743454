import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";

import { ChildThemeLayout } from "./Layout";
import { Provider } from "react-redux";
import { store } from "./_store";
import { config } from "./config";
const ChildTheme = (props) => {
  const getTheameColor = async () => {
    let configData = await fetch(`${config.localApiUrl}/api/loadconfig`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    });
    configData = await configData.json();
    localStorage.setItem("configData", JSON.stringify(configData.data));
    window.location.reload();
  };

  document.title = localStorage.getItem("configData")
    ? JSON.parse(localStorage.getItem("configData")).websiteName
    : "Sumo Ordering";
  document.getElementById("favicon").href = localStorage.getItem("configData")
    ? `${config.localApiUrl}/tenancy/assets/${
        JSON.parse(localStorage.getItem("configData")).favicon
      }`
    : getTheameColor();
  document.documentElement.style.setProperty(
    "--teal-bg",
    localStorage.getItem("configData") &&
      JSON.parse(localStorage.getItem("configData")).colorPalatte
  );
  document.documentElement.style.setProperty(
    "--text-teal",
    localStorage.getItem("configData") &&
      JSON.parse(localStorage.getItem("configData")).colorPalatte
  );
  document.documentElement.style.setProperty("--text-white", "#fff");
  document.documentElement.style.setProperty("--white-bg", "#fff");
  document.documentElement.style.setProperty("--text-black", "#000");
  document.documentElement.style.setProperty(
    "--secondary-color",
    localStorage.getItem("configData") &&
      JSON.parse(localStorage.getItem("configData")).SecondaryColorPalatte
  );

  let ogImage = document.querySelector('meta[property="og:image:secure_url"]');
  ogImage = document.createElement("meta");
  ogImage.setAttribute("property", "og:image:secure_url");
  document.head.appendChild(ogImage);
  ogImage.content =
    localStorage.getItem("configData") &&
    `${config.localApiUrl}/tenancy/assets/${
      JSON.parse(localStorage.getItem("configData")).favicon
    }`;

  const createRoutes = () => {
    return <Route path="/*" element={<ChildThemeLayout />} />;
  };

  return (
    <Provider store={store}>
      <Routes>{createRoutes()}</Routes>
    </Provider>
  );
};

export default ChildTheme;
